import axios, { AxiosError } from 'axios'
import { useMutation } from 'react-query'

const useApiPostStaticFile = () =>
  useMutation(async form => {
    try {
      const url = '/api/transactions/pricingList/UploadAsync'
      const result = await axios.post(url, form)
      return result
    } catch (e) {
      const error = e as AxiosError
      throw {
        error: error.response?.data,
        message: error.message
      }
    }
  })

export default useApiPostStaticFile
