import axios from 'axios'
import { useMutation } from 'react-query'

const useApiDeleteStaticFile = (pricelistfromAzure: boolean) =>
  useMutation(async (params: any) => {
    let url = '/api/pricelist'
    if (pricelistfromAzure) {
      url = '/api/transactions/pricingList/DeleteAsync'
    }
    const fileName = params.key
    const fileNameModified = fileName.substring(2)
    params.key = fileNameModified
    return await axios.post(url, params)
  })

export default useApiDeleteStaticFile
