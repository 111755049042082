import axios from 'axios'
import { useEffect, useState, useRef } from 'react'
import { Redirect } from 'react-router-dom'
import Cookies from 'js-cookie'
import SplashScreen from 'src/components/feedback/SplashScreen'
import getValueFromMilliseconds from 'src/helpers/getValueFromMilliseconds'
import { useRootStore } from 'src/contexts/StoreContext'
import { ROUTE_ADMIN, ROUTE_REDIRECT } from 'constants/routes'
import {
  EXPIRY_ID,
  IS_ADMIN,
  IDP_TOKEN,
  IDP_REFRESH,
  ISONEFLEETMIGRATED,
  ACCESS_LVL_CODE,
  USER_FIRSTNAME
} from 'constants/localStorage'
import useContent from 'hooks/useContent'
import getBrandFromDomain from 'helpers/getBrandFromDomain/getBrandFromDomain'
import { Identity_Platform } from 'constants/identityPlatforms'
import { getUserIdentityPlatform } from 'helpers/getUserIdentityPlatform'
import ErrorDisplay from 'components/feedback/ErrorDisplay'
import { getCIPLoginPath } from 'helpers/getForgeRockvalue'
import MigratedUsersPage from '../MigratedUsersPage'

function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[[\]]/g, '\\$&')
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

const HandleLoginPage = () => {
  const rootStore = useRootStore()
  const localStorage = window.localStorage
  const hasActiveSession = !!localStorage.getItem(EXPIRY_ID)
  const [justLoggedIn, setJustLoggedIn] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [isWaiting, setIsWaiting] = useState(false)
  const [userNotExist, setUserNotExist] = useState(false)
  const [isMigrateduser, setIsMigrateduser] =
    localStorage.getItem(ISONEFLEETMIGRATED) == 'true' ? useState(true) : useState(false)
  const [userName, setUserName] = useState('')
  const [authorityID, setAuthorityID] = useState('')
  const mountedRef = useRef(true)
  const content = useContent('session')
  const userIdentityPlatform =
    Cookies.get('bpf-idp') ?? getUserIdentityPlatform(window.localStorage.getItem('isInternal') ?? 'false')
  const brand = getBrandFromDomain()
  useEffect(() => {
    const requestJwt = async () => {
      const code = getParameterByName('code')
      const codeverifier = localStorage.getItem('code_verifier')
      if (!code || hasActiveSession) {
        setJustLoggedIn(true)
      } else {
        try {
          Cookies.set('brandfromdomain', brand)
          const response = await axios.post(
            `/api/users/token/gettokens`,
            {
              code: code,
              codeverifier: codeverifier,
              brand: brand,
              isLocal: Cookies.get('islocal')
            },
            {
              skipAuthRefresh: true,
              withCredentials: true
            }
          )
          // localStorage.setItem(IDP_IDToken, response?.data.id_token)
          localStorage.setItem(IDP_TOKEN, response?.data.access_token)
          localStorage.setItem(IDP_REFRESH, response?.data.refresh_token)
          if (!mountedRef.current) return null

          const authenticated = await axios.post(`/api/users/users/profile`, {
            token: localStorage.getItem(IDP_TOKEN),
            brand: brand ? brand : 'aral'
          })

          if (!mountedRef.current) return null
          const refreshExpiry = getValueFromMilliseconds(response.data.tokenExpirationTime, 'd')
          if (
            (authenticated.data.oneFleetMigratedStatus == 4 && authenticated.data.isOneFleetMigrated) ||
            Cookies.get('ismigrated')
          )
            setIsMigrateduser(true)
          setUserName(authenticated.data.given_name)
          setAuthorityID(authenticated.data.authorityID)
          // note - we neeed to wait for the OPA extract to refresh if cacheRefreshReqd flag is set (30 seconds)
          if (authenticated.data.cacheRefreshReqd) setIsWaiting(true)
          setTimeout(
            () => {
              Cookies.set('bpf-refresh-expiry', `expiry`, { expires: refreshExpiry, secure: true })
              localStorage.setItem(EXPIRY_ID, 'expiry')
              localStorage.setItem(IS_ADMIN, authenticated.data.isAdmin)
              localStorage.setItem(USER_FIRSTNAME, authenticated.data.given_name)
              localStorage.setItem(ACCESS_LVL_CODE, authenticated.data.authorityID)
              if (!mountedRef.current) return null
              setIsAdmin(authenticated.data.isAdmin)
              rootStore.init()

              setJustLoggedIn(true)
            },
            authenticated.data.cacheRefreshReqd ? 30000 : 0
          )
        } catch (e) {
          if (!mountedRef.current) return null
          if (
            (e.message.includes('Request failed with status code 400') &&
              parseInt(window.sessionStorage.getItem('retrycount'), 10) < 1) ||
            codeverifier == null
          ) {
            if (window.sessionStorage.getItem('retrycount')) {
              var count = window.sessionStorage.getItem('retrycount')
              window.sessionStorage.setItem('retrycount', count++)
            } else window.sessionStorage.setItem('retrycount', 1)
            window.location.href = await getCIPLoginPath()
          }
          console.warn(e)
          if (
            e.message.includes('Request failed with status code 404') &&
            userIdentityPlatform == Identity_Platform.AAD
          )
            setUserNotExist(true)
          setHasError(true)
        }
      }
    }

    requestJwt()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasActiveSession, rootStore, localStorage, brand, userIdentityPlatform])

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])
  if (isMigrateduser || Cookies.get('migrated')) {
    return <MigratedUsersPage username={userName} authorityID={authorityID} />
  } else if (hasActiveSession || justLoggedIn) {
    return isAdmin ? <Redirect to={ROUTE_ADMIN} /> : <Redirect to={ROUTE_REDIRECT} />
  }
  if (userNotExist) {
    return <ErrorDisplay text={'User is not Registered with the RIO application. Please Contact Support team.'} />
  } else if (hasError) {
    return <p>Error screen with instructions</p>
  }
  return <SplashScreen text={isWaiting ? content?.wait_while_setup || '[wait_while_setup]' : ''} />
}

export default HandleLoginPage
