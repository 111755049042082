import axios from 'axios'
import { useQuery } from 'react-query'

const QUERY_KEY = 'static-file-list'

const useApiGetStaticFileList = (authorityId: string) => {
  const url = '/api/transactions/pricingList/GetAsync'
  const params = {
    authorityIds: authorityId
  }
  return useQuery([QUERY_KEY, authorityId], async () => await axios.get(url, { params }), {
    staleTime: 120000
  })

  return useQuery([QUERY_KEY, authorityId], async () => await axios.get(url, { params }), {
    staleTime: 120000
  })
}

export default useApiGetStaticFileList
