import axios from 'axios'
import { useQuery } from 'react-query'
import cleanTransactionData from 'helpers/cleanTransactionData'

export const QUERY_KEY = 'transaction/id-'

const queryFn = async (transactionUniqueId: string, content: object) => {
  interface ReqConfigParams {
    productId?: string
    format: string
  }

  interface ReqConfig {
    params: ReqConfigParams
  }

  const reqConfig: ReqConfig = {
    params: {
      productId: '',
      format: ''
    }
  }
  reqConfig.params.productId = transactionUniqueId?.substring(36)
  if (reqConfig.params.productId == '') {
    delete reqConfig.params?.productId
  }
  reqConfig.params.format = 'v1_full'

  try {
    const response = await axios.get(
      `/api/transactions/transactions/${transactionUniqueId?.substring(0, 36)}`,
      reqConfig
    )
    return cleanTransactionData(JSON.parse(response.data), content)
  } catch (error) {
    console.warn(error)
  }
}

const useApiGetTransaction = (transactionUniqueId: string, transactionId: string, content: Record<string, unknown>) => {
  return useQuery(`${QUERY_KEY}${transactionId}`, () => queryFn(transactionUniqueId, content))
}

export default useApiGetTransaction
